import { AdminMarkdownPreview } from '../pages/Admin/MarkdownPreview';

function MarkdownRoute() {
  return <AdminMarkdownPreview />;
}

export const Component = MarkdownRoute;

export function clientLoader() {
  return null;
}
